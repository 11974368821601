import * as React from "react"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Chip from "@material-ui/core/Chip"
import Button from "@material-ui/core/Button"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import DiningOutlinedIcon from "@mui/icons-material/DiningOutlined"
import Alert from "@material-ui/lab/Alert"
import { navigate } from "gatsby"

import { updateOrderOptions } from "../../contexts/actions"
import { OrderOptionsContext } from "../../contexts"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: "3rem",
    maxWidth: "30rem",
    textAlign: "left",
  },
  alertBox: {
    justifyContent: "center",
    display: "flex",
  },
  outBox: {
    width: "100%",
    minHeight: "calc(100svh  - 66px)",
    position: "relative",
    overflowY: "scroll",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  chip: {
    marginTop: "1rem",
    marginBottom: "2.5rem",
    height: "2.5rem",
    minWidth: "4rem",
  },
  icon: {
    fontSize: "4rem !important",
  },
  butText: {
    fontSize: "1rem",
  },
  but: {
    padding: "1rem",
    marginBottom: "0.5rem",
  },
}))

const DineInCont = ({ storeSettings }) => {
  const [counter, setCounter] = React.useState(5)
  const [foundTable, setFoundTable] = React.useState(false)

  const { orderOptions, dispatchOrderOptions } =
    React.useContext(OrderOptionsContext)

  const classes = useStyles()

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const tableNo = parseInt(params.get("table"))

    if (tableNo) {
      if (
        tableNo >= storeSettings.tablesStartingNumber &&
        tableNo <= storeSettings.tablesEndingNumber &&
        !storeSettings.isDineInClosed &&
        !storeSettings.isResOutOfHours
      ) {
        dispatchOrderOptions(
          updateOrderOptions(
            "dineIn",
            tableNo,
            null,
            null,
            storeSettings.surcharge,
            Date.now()
          )
        )
        setFoundTable("YES")
      } else {
        dispatchOrderOptions(
          updateOrderOptions(
            storeSettings.isTakeAwayClosed ? null : "takeAway",
            null,
            null,
            null,
            {
              phSurchargeRate: 0,
              sunSurchargeRate: 0,
              satSurchargeRate: 0,
            },
            Date.now()
          )
        )
        setFoundTable("NO")
      }
    } else {
      navigate("/menu")
    }
  }, [
    dispatchOrderOptions,
    storeSettings.tablesEndingNumber,
    storeSettings.tablesStartingNumber,
    storeSettings.isDineInClosed,
    storeSettings.isResOutOfHours,
    storeSettings.isTakeAwayClosed,
    storeSettings.surcharge,
  ])
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (orderOptions.orderTableNumber) {
        if (counter > 0) {
          setCounter(counter - 1)
        } else {
          navigate("/menu")
        }
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [counter, orderOptions.orderTableNumber])

  return storeSettings.error ? (
    <>
      <ErrorOutlineOutlinedIcon classes={{ root: classes.icon }} />

      <Typography variant="h4" align={"center"} paragraph>
        {"Oops... an error occurred"}
      </Typography>
      <Typography variant="body1" align={"center"} paragraph>
        {
          "Please try again shortly, if problem persists please report it to one of our staff."
        }
      </Typography>
    </>
  ) : storeSettings.isDineInClosed ? (
    <>
      <ErrorOutlineOutlinedIcon classes={{ root: classes.icon }} />

      <Typography variant="h4" align={"center"} paragraph>
        {"Service Unavailable"}
      </Typography>
      <Typography variant="body1" align={"center"} paragraph>
        {!storeSettings.isResOutOfHours
          ? "Unfortunately our online Dine-In service is currently unavailable, please order at the counter, we apologise for the inconvenience."
          : "Unfortunately our online Dine-In service is currently unavailable, we apologise for the inconvenience."}
      </Typography>
    </>
  ) : !storeSettings.isDineInClosed && storeSettings.isResOutOfHours ? (
    <>
      <ErrorOutlineOutlinedIcon classes={{ root: classes.icon }} />

      <Typography variant="h4" align={"center"} paragraph>
        {"Kitchen Closed"}
      </Typography>
      <Typography variant="body1" align={"center"} paragraph>
        {
          "Unfortunately our kitchen is now closed, and our Dine-In service is not available."
        }
      </Typography>
    </>
  ) : orderOptions.orderTableNumber && foundTable === "YES" ? (
    <>
      {storeSettings.minutesToClose < 60 ? (
        <Box classes={{ root: classes.alertBox }}>
          <Alert severity="error" classes={{ root: classes.alert }}>
            <Typography
              variant={"body1"}
            >{`The kitchen is closing in ${storeSettings.minutesToClose} minutes.`}</Typography>
          </Alert>
        </Box>
      ) : (
        <></>
      )}
      <DiningOutlinedIcon classes={{ root: classes.icon }} />
      <Typography variant="h4">{"Dining-In at"}</Typography>
      <Chip
        classes={{ root: classes.chip }}
        label={
          <Typography variant="h6">{`Table ${orderOptions.orderTableNumber}`}</Typography>
        }
      />
      <Box>
        <Button
          variant="contained"
          size="large"
          color="primary"
          disableElevation
          classes={{ root: classes.but }}
          onClick={() => {
            navigate("/menu")
          }}
        >
          <Typography variant="body1" classes={{ root: classes.butText }}>
            {"Start my order now"}
          </Typography>
        </Button>
        <Typography variant="body1" align={"center"}>
          {`auto starting in ${counter}s`}
        </Typography>
      </Box>
    </>
  ) : foundTable === "NO" ? (
    <>
      <ErrorOutlineOutlinedIcon classes={{ root: classes.icon }} />

      <Typography variant="h4" align={"center"} paragraph>
        {"Unavailable Table Number"}
      </Typography>
      <Typography variant="body1" align={"center"} paragraph>
        {
          "Unfortunately this table number is unavailable, please report this to one of our staff."
        }
      </Typography>
    </>
  ) : (
    <></>
  )
}

export default DineInCont

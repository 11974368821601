import * as React from "react"
import axios from "axios"
import Layout from "../components/ui/layout"
import Seo from "../components/ui/seo"
import isClosed from "../helperFunc/isClosed"
import DineInCont from "../components/dineIn/dineInCont"

import Box from "@material-ui/core/Box"

import CircularProgress from "@material-ui/core/CircularProgress"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: "3rem",
    maxWidth: "30rem",
    textAlign: "left",
  },
  alertBox: {
    justifyContent: "center",
    display: "flex",
  },
  outBox: {
    width: "100%",
    minHeight: "calc(100svh  - 66px)",
    position: "relative",
    overflowY: "scroll",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  chip: {
    marginTop: "1rem",
    marginBottom: "2.5rem",
    height: "2.5rem",
    minWidth: "4rem",
  },
  icon: {
    fontSize: "4rem !important",
  },
  butText: {
    fontSize: "1rem",
  },
  but: {
    padding: "1rem",
    marginBottom: "0.5rem",
  },
}))

const DineIn = () => {
  const [loading, setLoading] = React.useState(true)
  const [storeSettings, setStoreSettings] = React.useState({})

  const classes = useStyles()

  React.useEffect(() => {
    axios
      .get(
        process.env.GATSBY_STRAPI_URL + "/api/store-setting/get-store-setting"
      )
      .then(response => {
        let timeDiff = Date.now() - response.data.dateNow
        let {
          isResOutOfHours,
          minutesToClose,
          surcharge,
          orderWindows,
          tableList,
        } = isClosed(response.data, timeDiff)
        let dataToSet = {
          ...response.data,
          isResOutOfHours: isResOutOfHours,
          minutesToClose: minutesToClose,
          surcharge: surcharge,
          timeDiff: timeDiff,
          orderWindows: orderWindows,
          tableList: tableList,
        }
        setStoreSettings(dataToSet)
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setStoreSettings({ error: true })
        setLoading(false)
      })
  }, [])

  return (
    <Layout footerOne={false} footerTwo={true}>
      <Seo title="Dine-In" />
      <Box classes={{ root: classes.outBox }}>
        <Box classes={{ root: classes.inBox }}>
          {loading ? (
            <CircularProgress color="primary" />
          ) : (
            <DineInCont storeSettings={storeSettings} />
          )}
        </Box>
      </Box>
    </Layout>
  )
}

export default DineIn
